import {AnalyticsClient, getOptionsFromMeta} from '@github/hydro-analytics-client'
import type {Context} from '@github/hydro-analytics-client'
import {getOrCreateOctolyticsId} from './octolytics-utils'

const dimensionPrefix = 'dimension_'
let hydroAnalyticsClient: AnalyticsClient | undefined

try {
  const options = getOptionsFromMeta('octolytics')

  if (options.baseContext) {
    delete options.baseContext.app_id
    delete options.baseContext.event_url
    delete options.baseContext.host

    for (const key in options.baseContext) {
      // some octolytics meta tags are prefixed with dimension-, which we don't need with the new hydro-analytics-client
      if (key.startsWith(dimensionPrefix)) {
        options.baseContext[key.replace(dimensionPrefix, '')] = options.baseContext[key]
        delete options.baseContext[key]
      }
    }
  }

  hydroAnalyticsClient = new AnalyticsClient({
    ...options,
    clientId: getOrCreateOctolyticsId()
  })
} catch (_) {
  // Failed to get options from meta tags.  This most likely means analytics are disabled.
}

export function sendPageView(context?: Context) {
  hydroAnalyticsClient?.sendPageView(context)
}

export function sendEvent(type: string, context: Context) {
  const service = document.head?.querySelector<HTMLMetaElement>('meta[name="current-catalog-service"]')?.content
  hydroAnalyticsClient?.sendEvent(type, {...(service ? {service} : {}), ...context})
}
