import {getCookies, setCookie} from './cookies'

function generateOctolyticsId() {
  const time = new Date().getTime()
  const clientId = `${Math.round(Math.random() * (Math.pow(2, 31) - 1))}.${Math.round(time / 1000)}`
  const cookie = `GH1.1.${clientId}`
  const expiration = new Date(time + 1 * 365 * 86400 * 1000).toUTCString()
  setCookie('_octo', cookie, expiration)
  return clientId
}

export function getOctolyticsId() {
  let clientId = ''
  const cookies = getCookies('_octo')
  const clientIds = []
  for (const cookie of cookies) {
    const splitOut = cookie.value.split('.')
    const first = splitOut.shift()
    if (first === 'GH1' && splitOut.length > 1) {
      const version = (splitOut.shift() || '').split('-')
      if (version.length === 1) {
        version[1] = '1'
      }
      const numberVersion = [Number(version[0]), Number(version[1])]
      clientId = splitOut.join('.')
      clientIds.push([numberVersion, clientId])
    }
  }
  clientId = ''
  if (clientIds.length > 0) {
    clientId = String(clientIds.sort().reverse()[0][1])
  }
  return clientId
}

export function getOrCreateOctolyticsId() {
  return getOctolyticsId() || generateOctolyticsId()
}
